import React from "react"
import LayoutComponent from "../components/Layout.js"
import Knap from "../components/Knap.js"

const IndexPage = () => (
    <LayoutComponent>
        <h1>Om Easymed</h1>
        <p>Hos Easymed har vi et tæt og tillidsfuldt samarbejde med både vores kunder og leverandører i branchen. Siden vi startede i 1996 har vi været en fast og stabil leverandør af røntgentilbehør, udstyr og forbrugsartikler i hele Skandinavien.</p>
        <p>Vores sortiment udvider sig løbende i takt med de nyeste radiologiske innovationer. Hvis du søger noget specifikt, så opfordrer vi dig til at tage kontakt til os direkte</p><br />
        <section className="easymed__grid__wrapper">
            <div className="easymed__grid__single__contact">
                <p className="medarbejder__navn">Benjamin Broen</p>
                <p className="medarbejder__titel">Direktør</p>
                <p className="medarbejder__tlf"><a href="tel:004527205354">+45 27205354</a></p>
            </div>
            <div className="easymed__grid__single__contact">
                <p className="medarbejder__navn">Lars Broen</p>
                <p className="medarbejder__titel">Salgsrepræsentant</p>
                <p className="medarbejder__tlf"><a href="tel:004526731454">+45 26731454</a></p>
            </div>
        </section>
        <Knap />
    </LayoutComponent>
)

export default IndexPage